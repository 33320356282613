import { CUSTOMER_CASE_DETAIL_ACTIONS } from 'modules/customer-cases/case-detail/middleware/action-types';
import { Reducer } from 'redux';
import {
  buildPepSanctionQuestionKey,
  transformScreeningHitRecordEntityToJson,
} from 'utils/helpers/cases/case-utility';
import { createAndDownloadBlobFile } from 'utils/helpers/file.helpers';

interface CustomerCaseDetailState {
  crrCase?: {
    caseUuid?: string;
    sanctionEvents?: any;
    screeningHitEvents?: any;
    caseStatus?: string;
    metadatas?: { key: string; values: any }[];
  };
  caseNotes?: any[]; // TODO: set correct type
  pepSanctionRisk?: any; // TODO: set correct type
  possibleActions?: unknown;
  manualRiskLevelCaseUuid?: unknown;
}

const initialState: CustomerCaseDetailState = {
  crrCase: {},
  caseNotes: [],
  pepSanctionRisk: undefined,
};

const customerCaseDetailReducer: Reducer<CustomerCaseDetailState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.data: {
      return {
        ...state,
        crrCase: undefined,
        caseNotes: undefined,
        pepSanctionRisk: undefined,
      };
    }
    case CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.stateUpdate.success:
    case CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.success: {
      const { caseNotes, pepSanctionRisk } = action.payload.data;

      // TODO (ERS) we don't need this when we stop saving "record" and "entity" as string
      const screeningHitEvents = action.payload.data.crrCase?.screeningHitEvents || [];
      screeningHitEvents.forEach((she: any) => {
        transformScreeningHitRecordEntityToJson(she.hits);
      });

      return {
        ...state,
        crrCase: action.payload.data.crrCase,
        possibleActions: action.payload.data.possibleActions,
        caseNotes: caseNotes && caseNotes.length > 0 ? caseNotes : state.caseNotes,
        pepSanctionRisk: pepSanctionRisk ?? state.pepSanctionRisk,
      };
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.add.success: {
      return {
        ...state,
        caseNotes: action.payload.data,
      };
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.manualRiskLevelChange.success: {
      return {
        ...state,
        manualRiskLevelCaseUuid: action.payload.data.createdCaseId,
      };
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.caseNotes.downloadAttachment.success: {
      createAndDownloadBlobFile(action.payload.blob, action.payload.properties.fileName);

      const { caseNotes } = state;
      const noteIndex =
        caseNotes?.findIndex((x) => x.noteId === action.payload.properties.noteId) ?? 0;
      const attachmentIndex = caseNotes?.[noteIndex]?.attachments?.findIndex(
        (x: { uniqueId: string }) => x.uniqueId === action.payload.properties.attachmentId,
      );

      if (caseNotes) {
        caseNotes[noteIndex].attachments[attachmentIndex].blobData = action.payload.blob;
      }

      return {
        ...state,
        caseNotes,
      };
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.fetch.error: {
      return {
        ...state,
        crrCase: undefined,
      };
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.caseDetail.reset: {
      return {};
    }

    case CUSTOMER_CASE_DETAIL_ACTIONS.pepSanction.assess.success: {
      const { pepSanctionRisk } = state;
      const assessment = action.payload.data;
      const { provider, providerId, sourceField, instanceId } = assessment?.custom || {};
      const questionKey = buildPepSanctionQuestionKey(
        provider,
        providerId,
        sourceField,
        undefined,
        undefined,
        instanceId,
        true,
      );
      const assessmentAnnouncedEvents = pepSanctionRisk?.assessmentAnnouncedEvents ?? {};
      assessmentAnnouncedEvents[questionKey] = { assessment };
      return {
        ...state,
        pepSanctionRisk: {
          ...pepSanctionRisk,
          assessmentAnnouncedEvents,
        },
      };
    }

    default:
      return state;
  }
};

export default customerCaseDetailReducer;
