class CustomerDetailManager {
  constructor(communicator) {
    if (communicator) {
      this.communicator = communicator;
    } else {
      throw new Error('Customer Detail API Communicator instance is required.');
    }
  }

  fetch(customerIdToken) {
    return this.communicator.fetch(customerIdToken);
  }

  getAllCustomerCases(customerIdToken) {
    return this.communicator.getAllCustomerCases(customerIdToken);
  }

  getMoreCustomerCases({ scrollId, customerIdToken }) {
    return this.communicator.getMoreCustomerCases({ scrollId, customerIdToken });
  }

  getAllTransactionCases(customerIdToken) {
    return this.communicator.getAllTransactionCases(customerIdToken);
  }

  getMoreTransactionCases({ scrollId, customerIdToken }) {
    return this.communicator.getMoreTransactionCases({ scrollId, customerIdToken });
  }
}

export default CustomerDetailManager;
