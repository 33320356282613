import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LocalStorageService from 'services/local-storage-service';
import { DEFAULT_LANGUAGE } from 'utils/constants/languages';

const localStorageService = new LocalStorageService();
const selectedLanguage = localStorageService.getLanguage().language;

/**
 * settings for loading language files
 */
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: DEFAULT_LANGUAGE,
    lng: selectedLanguage,
    interpolation: {
      escapeValue: false,
    },
    returnObjects: true,
    ns: ['translation', 'configuration', 'case', 'mapping', 'dashboard', 'sar', 'error'],
    defaultNS: 'translation',
    backend: {
      allowMultiLoading: true,
      // after releases, we display incorrect mappings due to i18n caching
      // hence, for the time being we disable the cache
      requestOptions: {
        cache: 'no-store',
      },
      loadPath: '/i18n/{{lng}}/{{ns}}.json',
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
