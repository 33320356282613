import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EntityResolutionProfile } from 'modules/entity-resolution/models/entity-resolution-profile';

export interface EntityProfileRequest {
  customerIdToken: string;
}

const initialState: EntityResolutionProfile = {
  entity: null,
};

const entityResolutionProfileSlice = createSlice({
  name: 'entityResolutionProfile',
  initialState,
  reducers: {
    getEntityResolutionProfile: (state, _action: PayloadAction<EntityProfileRequest>) => state,
    getEntityResolutionProfileSuccess: (state, action: PayloadAction<EntityResolutionProfile>) => {
      const newState = {
        ...state,
        ...action?.payload,
      };
      return newState;
    },
  },
});

export const { getEntityResolutionProfile, getEntityResolutionProfileSuccess } =
  entityResolutionProfileSlice.actions;

export default entityResolutionProfileSlice.reducer;
