import GlobalContext from 'contexts/global/global-context';
import globalStateEmpty from 'contexts/global/global-state-empty';
import { useDrawer } from 'hooks/use-drawer';
import { isEmpty } from 'lodash';
import { clearCaseDetail } from 'middleware/actions/case';
import { Tenant } from 'models/tenant/tenant';
import { useAuthentication } from 'modules/authentication/login/hooks/use-auth';
import { getPlatformViewingConfig } from 'modules/authentication/login/middleware/actions/auth-actions';
import { useSidebarToggle } from 'modules/layout/main-view/hooks/use-sidebar-toggle';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TenantManagementService from 'services/tenant/tenant-management-service';
import { getMatchRoute } from 'utils/constants/routes';
import { getTenantsFromString } from 'utils/helpers/utility';

interface GlobalStateProps {
  children: ReactNode;
}

const GlobalState: FC<GlobalStateProps> = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthentication();
  const { drawerWidth, setDrawerWidth } = useDrawer();
  const { isOpen: isSidebarOpen, toggleSidebar } = useSidebarToggle();

  const [filtersShouldBeOpened, setFiltersShouldBeOpened] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>(
    TenantManagementService.getLastActiveTenant(),
  );

  const parseTenantIdFromURL = () => {
    const matchingRoute = getMatchRoute(window.location.pathname, true);
    // TODO
    const { tenantId } = matchingRoute?.matchRoute?.params ?? { tenantId: null };

    if (isEmpty(tenantId)) return;
    const tenants: Array<Tenant> = getTenantsFromString(TenantManagementService.getTenants());
    const filteredTenant = tenants.find((tenant: Tenant) => tenant.id === tenantId);

    if (!isEmpty(filteredTenant)) {
      TenantManagementService.setActiveTenant(filteredTenant);
      TenantManagementService.setLastActiveTenant(filteredTenant);
      setSelectedTenant(filteredTenant);
    } else {
      TenantManagementService.setActiveTenantId(tenantId);
      TenantManagementService.setTenantName('');

      TenantManagementService.setLastTenantId(tenantId);
      TenantManagementService.setLastTenantName('');

      setSelectedTenant({ id: tenantId });
    }
  };

  // assign correct active tenant after logging in
  useEffect(() => {
    if (isAuthenticated) {
      const tenantId = TenantManagementService.getActiveTenantId();
      const tenants = getTenantsFromString(TenantManagementService.getTenants());
      const filteredTenant = tenants.find((tenant: Tenant) => tenant.id === tenantId);
      // Array.find might return 'undefined' when the user is not assigned to any tenant
      setSelectedTenant(filteredTenant || globalStateEmpty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // assign correct active tenant if detected from url on first render of application
  useEffect(() => {
    if (isAuthenticated) {
      parseTenantIdFromURL();
      dispatch(getPlatformViewingConfig());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const handleSetSelectedTenant = (selectedTenant: Tenant) => {
    dispatch(clearCaseDetail());

    TenantManagementService.setActiveTenant(selectedTenant);
    TenantManagementService.setLastActiveTenant(selectedTenant);

    setSelectedTenant(selectedTenant);
    dispatch(getPlatformViewingConfig());
  };

  return (
    <GlobalContext.Provider
      value={{
        rightPermanentDrawerWidth: drawerWidth,
        filtersShouldBeOpened,
        selectedTenant,
        isSidebarOpen,
        toggleSidebar,
        setRightPermanentDrawerWidth: setDrawerWidth,
        setFiltersShouldBeOpened,
        handleSetSelectedTenant,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
