import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import {
  getScreeningListSuccess,
  getScreeningListAttributesSuccess,
} from 'modules/configuration/list-manager/data-provider-list/reducers/data-provider-list-config-reducer';
import { put, takeLatest } from 'redux-saga/effects';
import { showError } from 'middleware/actions/error';
import ScreeningListAPI from 'modules/configuration/list-manager/data-provider-list/services/screening-list-api';

const communicator = new ScreeningListAPI();

function* getScreeningListBegin(action) {
  yield put(startProcessing('dataProviderListConfig/getScreeningList'));
  try {
    const json = yield communicator.fetchScreeningList(action.payload);
    const { search, jurisdiction, listType, pageSize, pageNumber, toDoAfterFetching } =
      action.payload;
    const dataLength = json.length;
    const hasMore = dataLength === pageSize;

    if (toDoAfterFetching) {
      toDoAfterFetching(json);
    }

    yield put(
      getScreeningListSuccess({
        screeningLists: json,
        paging: {
          searchTerm: search,
          jurisdiction,
          listType,
          latestPage: pageNumber || 1,
          hasMore,
        },
      }),
    );
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderListConfig/getScreeningList'));
}

export function* getScreeningListWatcher() {
  yield takeLatest('dataProviderListConfig/getScreeningList', getScreeningListBegin);
}

function* getScreeningListAttributesBegin(action) {
  yield put(startProcessing('dataProviderListConfig/getScreeningListAttributes'));
  try {
    const json = yield communicator.fetchScreeningAttributes(action.payload);

    yield put(
      getScreeningListAttributesSuccess({
        jurisdictionCategories: json.jurisdictionName,
        listTypeCategories: json.listTypeName,
      }),
    );
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('dataProviderListConfig/getScreeningList'));
}

export function* getScreeningListAttributesWatcher() {
  yield takeLatest(
    'dataProviderListConfig/getScreeningListAttributes',
    getScreeningListAttributesBegin,
  );
}
