import APICommunicator from 'services/api-communicator';
import { API_URLS } from 'utils/constants/api-config';
import ErrorCode from 'utils/constants/error-code';
import PageSize from 'utils/constants/page-size';

const buildPayloadForCustomerCases = (customerIdToken, scrollId = '') => ({
  paging: { pageSize: PageSize.CUSTOMER_DUE_DILIGENCE, scrollId },
  filtering: {
    filters: [
      {
        condition: 'TEXT_EQUAL',
        field: 'customerIdToken',
        values: [customerIdToken],
      },
    ],
    operator: 'AND',
  },
});

const buildPayloadForTransactionCases = (customerIdToken, scrollId = '') => ({
  paging: { pageSize: PageSize.CUSTOMER_DUE_DILIGENCE, scrollId },
  filtering: {
    filters: [
      {
        condition: 'TEXT_EQUAL',
        field: 'masterData.accountHoldingPartyDataCustomerId.token',
        values: [customerIdToken],
      },
    ],
    operator: 'AND',
  },
  sorting: {
    orders: [
      {
        field: 'createdAt',
        direction: 'DESC',
      },
    ],
  },
});

class CustomerDetailApiCommunicator extends APICommunicator {
  fetch(customerIdToken) {
    const apiUrl = API_URLS.customerData.customerDetail.fetch;

    const payload = {
      paging: { pageSize: 1, scrollId: '' },
      filtering: {
        filters: [
          {
            condition: 'TEXT_EQUAL',
            field: '_id',
            values: [customerIdToken],
          },
        ],
        operator: 'AND',
      },
    };
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getAllCustomerCases(customerIdToken) {
    const apiUrl = API_URLS.customerService.cases.get;

    const payload = buildPayloadForCustomerCases(customerIdToken);
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getMoreCustomerCases({ scrollId, customerIdToken }) {
    const apiUrl = API_URLS.customerService.cases.get;

    const payload = buildPayloadForCustomerCases(customerIdToken, scrollId);

    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getAllTransactionCases(customerIdToken) {
    const apiUrl = API_URLS.caseService.search;

    const payload = buildPayloadForTransactionCases(customerIdToken);
    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }

  getMoreTransactionCases({ scrollId, customerIdToken }) {
    const apiUrl = API_URLS.caseService.search;

    const payload = buildPayloadForTransactionCases(customerIdToken, scrollId);

    return this.postJSONForTenant(apiUrl, payload, ErrorCode.CASE_MANAGER_BACKEND_NOT_AVAILABLE);
  }
}

export default CustomerDetailApiCommunicator;
