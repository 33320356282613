import config from 'utils/helpers/config-utils/config';

export const API_PARAMS = {
  tenantId: '{{tenantId}}',
  sourceTenantId: '{{sourceTenantId}}',
  version: '{{version}}',
  userId: '{{userId}}',
  correlationId: '{{correlationId}}',
  forwardingToCaseId: '{{forwardingToCaseId}}',
  caseId: '{{caseId}}',
  customerIdToken: '{{customerIdToken}}',
  noteId: '{{noteId}}',
  isGroupCase: '{{isGroupCase}}',
  queryString: '{{queryString}}',
  configVersion: '{{configVersion}}',
  id: '{{id}}',
  riskFactorId: '{{riskFactorId}}',
  provider: '{{provider}}',
  status: '{{status}}',
  changeRequestId: '{{changeRequestId}}',
  requestId: '{{requestId}}',
  fromDate: '{{fromDate}}',
  toDate: '{{toDate}}',
  jobExecutionId: '{{jobExecutionId}}',
  samplingRate: '{{samplingRate}}',
};

export const API_URLS = {
  logService: {
    addLog: `${config.baseUrl}/log`,
  },
  tenantService: {
    addTenant: `${config.configurationUrl}/tenant/createSubTenant`,
    getAllTenants: `${config.configurationUrl}/tenant`,
    submitTenantHierarchy: `${config.configurationUrl}/tenant/hierarchy`,
    callback: {
      getConfig: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}/callback`,
      detokenizeSecret: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}/callback/secret/detokenize`,
    },
    scorechain: {
      getConfig: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}/scorechain`,
      detokenizeSecret: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}/scorechain/secret/detokenize`,
    },
    getConfig: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}`,
    exportCsv: `${config.baseUrlV2}/tenant/csv/export/download`,
    search: `${config.configurationUrl}/tenant/_search`,
    apiKey: `${config.configurationUrl}/tenant/api-keys`,
    allowedTenants: `${config.baseUrl}/users/allowed-tenants`,
    userManagement: `${config.configurationUrl}/config/tenant/${API_PARAMS.tenantId}/user-management`,
  },
  masterTenantService: {
    getMasterTenants: `${config.userUrl}/masterTenants`,
  },
  mccService: {
    getMetadata: `${config.configurationUrl}/mcc/metadata/${API_PARAMS.tenantId}`,
    updateActiveVersion: `${config.configurationUrl}/mcc/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
    uploadFile: `${config.configurationUrl}/mcc/upload/${API_PARAMS.tenantId}?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
    downloadFile: `${config.configurationUrl}/mcc/csv/${API_PARAMS.tenantId}`,
  },
  blacklistService: {
    getMetadata: `${config.configurationUrl}/blacklist/metadata/${API_PARAMS.tenantId}`,
    uploadFile: `${config.configurationUrl}/blacklist/upload/${API_PARAMS.tenantId}?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
    downloadFile: `${config.configurationUrl}/blacklist/csv/${API_PARAMS.tenantId}`,
  },
  hidtaHifca: {
    getMetadata: `${config.configurationUrl}/hidtahifca/metadata/${API_PARAMS.tenantId}`,
    updateActiveVersion: `${config.configurationUrl}/hidtahifca/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
    uploadFile: `${config.configurationUrl}/hidtahifca/upload/${API_PARAMS.tenantId}`,
    downloadFile: `${config.configurationUrl}/hidtahifca/csv/${API_PARAMS.tenantId}`,
  },
  occupationService: {
    getMetadata: `${config.configurationUrl}/occupation/metadata/${API_PARAMS.tenantId}`,
    updateActiveVersion: `${config.configurationUrl}/occupation/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
    uploadFile: `${config.configurationUrl}/occupation/upload/${API_PARAMS.tenantId}?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
    downloadFile: `${config.configurationUrl}/occupation/csv/${API_PARAMS.tenantId}`,
  },
  productTypeService: {
    getMetadata: {
      individual: `${config.configurationUrl}/productType/metadata/${API_PARAMS.tenantId}/INDIVIDUAL`,
      organization: `${config.configurationUrl}/productType/metadata/${API_PARAMS.tenantId}/ORGANIZATION`,
    },
    updateActiveVersion: {
      individual: `${config.configurationUrl}/productType/activate/${API_PARAMS.tenantId}/INDIVIDUAL/${API_PARAMS.version}`,
      organization: `${config.configurationUrl}/productType/activate/${API_PARAMS.tenantId}/ORGANIZATION/${API_PARAMS.version}`,
    },
    uploadFile: {
      individual: `${config.configurationUrl}/productType/upload/${API_PARAMS.tenantId}/INDIVIDUAL?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
      organization: `${config.configurationUrl}/productType/upload/${API_PARAMS.tenantId}/ORGANIZATION?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
    },
    downloadFile: {
      individual: `${config.configurationUrl}/productType/csv/${API_PARAMS.tenantId}/INDIVIDUAL`,
      organization: `${config.configurationUrl}/productType/csv/${API_PARAMS.tenantId}/ORGANIZATION`,
    },
  },
  crrRiskFactors: {
    riskLevelMaps: {
      uploadCsv: `${config.configurationUrl}/tenants/${API_PARAMS.tenantId}/crr-risk-factors/${API_PARAMS.riskFactorId}/risk-level-maps/upload-csv`,
      downloadCsv: `${config.configurationUrl}/tenants/${API_PARAMS.tenantId}/crr-risk-factors/${API_PARAMS.riskFactorId}/risk-level-maps/csv`,
      getMetadata: `${config.configurationUrl}/tenants/${API_PARAMS.tenantId}/crr-risk-factors/${API_PARAMS.riskFactorId}/risk-level-maps/metadata`,
    },
  },
  accountBasedFrequencyService: {
    increaseDecrease: {
      getData: `${config.configurationUrl}/account-based-frequency-increase-decrease`,
    },
  },
  caseService: {
    groupCase: {
      queryMembers: `${config.baseUrl}/cases/group-case/cases/${API_PARAMS.forwardingToCaseId}`,
      stopGrouping: `${config.baseUrl}/cases/group-case/${API_PARAMS.caseId}/stopGrouping`,
    },

    updateCaseStatus: `${config.baseUrl}/cases/updateStatus`,
    audit: {
      userIds: `${config.baseUrl}/cases/auditTrack/user-ids`,
    },
    search: `${config.baseUrlV2}/cases/search`,
    notes: {
      userIds: `${config.baseUrl}/cases/notes/user-ids`,
    },
    singleCase: {
      fetchDetails: `${config.baseUrl}/cases/${API_PARAMS.caseId}`,
      decryptAll: `${config.baseUrl}/cases/${API_PARAMS.caseId}/decrypt-all`,
      notes: {
        addNote: `${config.baseUrlV2}/cases/${API_PARAMS.caseId}/notes`,
      },
      states: {
        possibleStates: `${config.baseUrl}/cases/${API_PARAMS.caseId}/states`,
        updateCaseState: `${config.baseUrl}/cases/${API_PARAMS.caseId}/states-v2`,
      },
    },
    collection: {
      get: `${config.baseUrlV2}/cases/search/getCollection`,
    },
    bulkCaseAcceptance: {
      acceptMultipleCases: `${config.baseUrl}/cases/bulkAcceptance/csv?`,
    },
  },
  filesService: {
    notes: {
      getFile: `${config.baseUrl}/files/notes/${API_PARAMS.noteId}/${API_PARAMS.id}`,
    },
  },
  groupService: {
    search: `${config.baseUrlV2}/groups/search`,
    export: `${config.baseUrlV2}/groups/search/export`,
  },
  assessmentService: {
    assessCase: `${config.baseUrl}/assessment/${API_PARAMS.caseId}?groupCase=${API_PARAMS.isGroupCase}`,
    pepSanction: {
      assess: `${config.pepSanctionBaseUrl}/assessments/cases/${API_PARAMS.caseId}`,
    },
  },
  configService: {
    updatePepSanctionConfig: `${config.configurationUrl}/config/pepsanction`,
    generateCsv: `${config.configurationUrl}/config/csv`,
    instanciablePepSanction: {
      get: `${config.configurationUrl}/config/pepsanction/instanciable/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/pepsanction/instanciable/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/pepsanction/instanciable/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
      updateProvider: `${config.configurationUrl}/config/pepsanction/instanciable/provider/${API_PARAMS.tenantId}?provider=${API_PARAMS.provider}`,
    },
    blacklist: {
      get: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    getConfigWithVersion: `${config.configurationUrl}/config/${API_PARAMS.tenantId}/${API_PARAMS.configVersion}`,
    decisionEngine: {
      getConfig: `${config.configurationUrl}/config/decision/${API_PARAMS.tenantId}`,
    },
    countryRisk: {
      getConfig: `${config.configurationUrl}/config/countryrisk/${API_PARAMS.tenantId}`,
    },
    counterPartyVolume: {
      fetch: `${config.configurationUrl}/config/counterparty-volume/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/counterparty-volume/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/counterparty-volume/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    fanIn: {
      getConfig: `${config.configurationUrl}/config/fan-in/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/fan-in/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/fan-in/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    behavioralAnalysis: {
      getConfig: `${config.configurationUrl}/config/behavioral-analysis/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/behavioral-analysis/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/behavioral-analysis/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    aiScorers: {
      behavioralAnalysis: {
        fetch: `${config.configurationUrl}/ai-scorers/behavioral-analysis/${API_PARAMS.tenantId}`,
      },
    },
    ruleCategory: {
      fetch: `${config.configurationV0Url}/rule-categories`,
    },
    plausibility: {
      getConfig: `${config.configurationUrl}/config/plausibility/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/plausibility/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/plausibility/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    ruleBuilder: {
      getConfig: `${config.configurationUrl}/config/rule-builder/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/rule-builder/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/rule-builder/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    referenceAccountV2: {
      getConfig: `${config.configurationUrl}/config/reference-account-v2/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/reference-account-v2/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/reference-account-v2/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    transactionFields: {
      getAllTransactionStrings: `${config.configurationUrl}/config/transactions/field-names`,
    },
    filterConfigs: {
      fetch: `${config.configurationUrl}/filter-configs`,
    },
  },
  reprocessingService: {
    v1: {
      reprocess: `${config.reprocessingUrl}/reprocess`,
    },
    v2: {
      query: `${config.reprocessingV2Url}/query`,
      reprocess: `${config.reprocessingV2Url}/reprocess`,
    },
  },
  experimentationService: {
    estimate: `${config.experimentationUrl}/tenants/${API_PARAMS.tenantId}/transaction-checks?sourceTenantId=${API_PARAMS.sourceTenantId}&fromDate=${API_PARAMS.fromDate}&toDate=${API_PARAMS.toDate}&samplingRate=${API_PARAMS.samplingRate}`,
    experiment: `${config.experimentationUrl}/tenants/${API_PARAMS.tenantId}/transaction-checks`,
    executionStatus: `${config.experimentationUrl}/executions/latest`,
  },
  report: {
    v1: {
      riskyWordsPattern: `/case-statistics/reports/risky-words/download?`,
    },
    v2: {
      groupStatistics: '/groups/report/group-statistics/download?',
      falsePositivePattern: '/groups/report/false-positive-pattern/download?',
      truePositivePattern: '/groups/report/true-positive-pattern/download?',
      casesPerformance: '/groups/report/cases-performance/download?',
    },
  },
  goAMLService: {
    getXML: `${config.baseUrl}/goaml-report/${API_PARAMS.caseId}`, // GET
    saveXML: `${config.baseUrl}/goaml-report/${API_PARAMS.caseId}`, // PUT
    validateXML: `${config.baseUrl}/goaml-report/validate/${API_PARAMS.caseId}`, // POST
  },
  dashboard: {
    caseStatisticsAndHealth: {
      groupsCount: `${config.baseUrlV2}/groups/report/groups-counts?${API_PARAMS.queryString}`,
      typesCount: `${config.baseUrlV2}/groups/report/types-counts?${API_PARAMS.queryString}`,
      groupsHealth: `${config.baseUrlV2}/groups/report/groups-health?${API_PARAMS.queryString}`,
    },
    efficiency: {
      falsePositivePattern: `${config.baseUrlV2}/groups/report/false-positive-pattern?${API_PARAMS.queryString}`,
      falsePositiveRate: `${config.baseUrlV2}/groups/report/false-positive-rate?${API_PARAMS.queryString}`,
      truePositivePattern: `${config.baseUrlV2}/groups/report/true-positive-pattern?${API_PARAMS.queryString}`,
      truePositivePatternByInstance: `${config.baseUrlV2}/groups/report/true-positive-pattern-by-instance?${API_PARAMS.queryString}`,
      casesPerformance: `${config.baseUrlV2}/groups/report/cases-performance?${API_PARAMS.queryString}`,
      riskyWordsPattern: `${config.baseUrl}/case-statistics/reports/risky-words?${API_PARAMS.queryString}`,
    },
    customerStatistics: {
      fetch: `${config.caseStatisticsUrl}/dashboards/data`,
    },
  },
  customerDetails: {
    get: `${config.baseUrl}/customer-details/${API_PARAMS.caseId}`,
  },
  config: {
    getVersion: `${config.configurationUrl}/config/${API_PARAMS.tenantId}/${API_PARAMS.configVersion}`,
    customerRiskRating: {
      organization: {
        get: `${config.configurationUrl}/config/customer-risk-rating/${API_PARAMS.tenantId}`,
        update: `${config.configurationUrl}/config/customer-risk-rating/${API_PARAMS.tenantId}`,
        createRiskFactor: `${config.configurationUrl}/config/customer-risk-rating/${API_PARAMS.tenantId}/risk-factors`,
        deleteRiskFactor: `${config.configurationUrl}/config/customer-risk-rating/${API_PARAMS.tenantId}/risk-factors/${API_PARAMS.riskFactorId}`,
        getConfigWithVersion: `${config.configurationUrl}/config/customer-risk-rating/${API_PARAMS.tenantId}/${API_PARAMS.configVersion}`,
      },
      individual: {
        get: `${config.configurationUrl}/config/customer-risk-rating/individual/${API_PARAMS.tenantId}`,
        update: `${config.configurationUrl}/config/customer-risk-rating/individual/${API_PARAMS.tenantId}`,
        createRiskFactor: `${config.configurationUrl}/config/customer-risk-rating/individual/${API_PARAMS.tenantId}/risk-factors`,
        deleteRiskFactor: `${config.configurationUrl}/config/customer-risk-rating/individual/${API_PARAMS.tenantId}/risk-factors/${API_PARAMS.riskFactorId}`,
        getConfigWithVersion: `${config.configurationUrl}/config/customer-risk-rating/individual/${API_PARAMS.tenantId}/${API_PARAMS.configVersion}`,
      },
    },
    passthroughV2: {
      fetch: `${config.configurationUrl}/config/passthrough-v2/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/passthrough-v2/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/passthrough-v2/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    passthroughV3: {
      fetch: `${config.configurationUrl}/config/passthrough-v3/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/passthrough-v3/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/passthrough-v3/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    riskyWordsV2: {
      getMetadata: `${config.configurationUrl}/config/risky-words-v2/metadata/${API_PARAMS.tenantId}`,
      updateActiveVersion: `${config.configurationUrl}/config/risky-words-v2/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      uploadFile: `${config.configurationUrl}/config/risky-words-v2/upload/${API_PARAMS.tenantId}?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
      downloadFile: `${config.configurationUrl}/config/risky-words-v2/csv/${API_PARAMS.tenantId}`,
      fetch: `${config.configurationUrl}/config/risky-words-v2/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/risky-words-v2/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/risky-words-v2/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    filters: {
      getMetadata: `${config.configurationUrl}/config/filters/metadata/${API_PARAMS.tenantId}`,
      updateActiveVersion: `${config.configurationUrl}/config/filters/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      uploadFile: `${config.configurationUrl}/config/filters/upload?userId=${API_PARAMS.userId}&correlationId=${API_PARAMS.correlationId}`,
      downloadFile: `${config.configurationUrl}/config/filters/csv`,
      fetch: `${config.configurationUrl}/config/filters/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/filters/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/filters/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    blacklist: {
      getMetadata: `${config.configurationUrl}/config/blacklist/metadata/${API_PARAMS.tenantId}`,
      updateActiveVersion: `${config.configurationUrl}/config/blacklist/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      uploadFile: `${config.configurationUrl}/config/blacklist/upload/${API_PARAMS.tenantId}`,
      downloadFile: `${config.configurationUrl}/config/blacklist/csv/${API_PARAMS.tenantId}`,
      fetch: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/blacklist/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    expectedCustomerValue: {
      fetch: `${config.configurationUrl}/config/expected-customer-value/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/expected-customer-value/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/expected-customer-value/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    changeRequest: {
      fetchAll: `${config.configurationUrl}/configs/change-requests`,
      approve: `${config.configurationUrl}/configs/change-requests/${API_PARAMS.changeRequestId}/approve`,
      deny: `${config.configurationUrl}/configs/change-requests/${API_PARAMS.changeRequestId}/deny`,
    },
    whitelabel: {
      logo: `${config.configurationUrl}/config/whitelabel/${API_PARAMS.tenantId}/logo`,
      favicon: `${config.configurationUrl}/config/${API_PARAMS.tenantId}/favicon`,
    },
    caseNotification: {
      testConfig: `${config.caseNotifications}/${API_PARAMS.id}/test`,
    },
    amlRiskScreening: {
      fetch: `${config.configurationUrl}/config/aml-risk-screening/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/aml-risk-screening/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/aml-risk-screening/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    screeningManager: {
      fetch: `${config.screeningUrl}/screening-lists`,
      fetchAttributes: `${config.screeningUrl}/screening-list-attributes`,
      fetchImportStatus: `${config.screeningUrl}/import-status`,
    },
    sftpConfiguration: {
      detokenize: `${config.configurationUrl}/sftp/${API_PARAMS.tenantId}/instances/${API_PARAMS.id}/detokenize`,
    },
  },
  listManager: {
    riskyWords: {
      updateActiveVersion: `${config.configurationUrl}/config/list-manager/risky-words/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      fetch: `${config.configurationUrl}/config/list-manager/risky-words/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/list-manager/risky-words/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/list-manager/risky-words/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    filters: {
      updateActiveVersion: `${config.configurationUrl}/config/list-manager/filters/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      fetch: `${config.configurationUrl}/config/list-manager/filters/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/list-manager/filters/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/list-manager/filters/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    blacklist: {
      updateActiveVersion: `${config.configurationUrl}/config/list-manager/blacklist/activate/${API_PARAMS.tenantId}/${API_PARAMS.version}`,
      fetch: `${config.configurationUrl}/config/list-manager/blacklist/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/list-manager/blacklist/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/list-manager/blacklist/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
    dataProviderList: {
      fetch: `${config.configurationUrl}/config/list-manager/data-provider/${API_PARAMS.tenantId}`,
      upsert: `${config.configurationUrl}/config/list-manager/data-provider/${API_PARAMS.tenantId}`,
      delete: `${config.configurationUrl}/config/list-manager/data-provider/${API_PARAMS.tenantId}?uniqueId=${API_PARAMS.id}`,
    },
  },
  entityResolution: {
    config: `${config.configurationUrl}/config/entity-resolution/${API_PARAMS.tenantId}`,
    entityProfile: {
      fetch: `${config.screeningUrl}/entity-profiles/${API_PARAMS.tenantId}/customer/${API_PARAMS.customerIdToken}`,
    },
  },
  customerList: {
    fetchAll: `${config.customerRiskRatingUrl}/v1/customers`,
  },
  customerData: {
    customerDetail: {
      fetch: `${config.customerRiskRatingUrl}/v1/customers`,
      decryptAll: `${config.customerRiskRatingUrl}/v1/customers/${API_PARAMS.id}/decrypt-all`,
      actions: {
        cases: {
          create: `${config.customerRiskRatingUrl}/customers/v1/${API_PARAMS.customerIdToken}/cases`,
        },
        check: {
          trigger: `${config.customerRiskRatingUrl}/customers/v2/${API_PARAMS.customerIdToken}/checks`,
          getStatus: `${config.customerRiskRatingUrl}/customers/v2/${API_PARAMS.customerIdToken}/checks/${API_PARAMS.requestId}/status`,
          finalize: `${config.customerRiskRatingUrl}/customers/v2/${API_PARAMS.customerIdToken}/checks/${API_PARAMS.requestId}/_finalize`,
        },
        checkIfExists: `${config.customerRiskRatingUrl}/v1/customers/${API_PARAMS.customerIdToken}/_check-if-exists`,
      },
    },
    adhocScreening: {
      post: `${config.customerRiskRatingUrl}/customers/v1/adhoc-screening`,
    },
    scheduledJobs: {
      customerChecks: {
        search: `${config.customerRiskRatingUrl}/v1/jobs/customer-checks/_search`,
        reRun: `${config.customerRiskRatingUrl}/v1/jobs/customer-checks/${API_PARAMS.jobExecutionId}/_re-run`,
        resume: `${config.customerRiskRatingUrl}/v1/jobs/customer-checks/${API_PARAMS.jobExecutionId}/_resume`,
      },
    },
  },
  settings: {
    get: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}`,
    getByVersion: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}/${API_PARAMS.configVersion}`,
    update: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}`,
    countryRisks: {
      add: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}/country-risks`,
      delete: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}/country-risks/${API_PARAMS.id}`,
      update: `${config.configurationUrl}/config/settings/${API_PARAMS.tenantId}/country-risks/${API_PARAMS.id}`,
    },
  },
  tenantManagementService: {
    masterTenant: {
      create: `${config.tenantManagementUrlV2}/master-tenants`,
      getCreationStatus: `${config.tenantManagementUrlV2}/master-tenants/creation-status/${API_PARAMS.requestId}`,
      finalize: `${config.tenantManagementUrlV2}/master-tenants/creation-status/${API_PARAMS.requestId}/_finalize`,
    },
  },
  customerService: {
    cases: {
      get: `${config.customerRiskRatingUrl}/customers/v1/cases`,
      export: `${config.customerRiskRatingUrl}/customers/v1/cases/export`,
    },
    singleCase: {
      getDetail: `${config.customerRiskRatingUrl}/customers/v1/cases/${API_PARAMS.caseId}`,
      decryptAll: `${config.customerRiskRatingUrl}/customers/v1/cases/${API_PARAMS.caseId}/decrypt-all`,
      updateState: `${config.customerRiskRatingUrl}/customers/v1/cases/${API_PARAMS.caseId}/states`,
      notes: {
        getAll: `${config.customerRiskRatingUrl}/customers/v2/cases/${API_PARAMS.caseId}/notes`,
        getFile: `${config.customerRiskRatingUrl}/customers/v2/cases/${API_PARAMS.caseId}/notes/${API_PARAMS.noteId}/files/${API_PARAMS.id}`,
      },
    },
    decrypt: {
      anyCase: `${config.customerRiskRatingUrl}/customers/v1/decrypt`,
    },
    requestManualRiskLevelChange: {
      customer: `${config.customerRiskRatingUrl}/customers/v1/${API_PARAMS.customerIdToken}/risk-level`,
    },
  },
  networkAnalysisService: {
    passthroughV2: {
      incoming: `${config.baseUrl}/network-analysis/passthrough-v2/incoming/${API_PARAMS.caseId}`,
      outgoing: `${config.baseUrl}/network-analysis/passthrough-v2/outgoing/${API_PARAMS.caseId}?`,
    },
    passthroughV3: {
      outgoing: `${config.baseUrl}/network-analysis/passthrough-v3/${API_PARAMS.caseId}`,
    },
  },
  authService: {
    login: {
      database: `${config.baseUrl}/auth0/login`,
      sso: `${config.baseUrl}/auth0/ssoLogin`,
    },
    resetPassword: {
      post: `${config.auth0BaseUrl}/resetPassword`,
    },
  },
  auditTrailService: {
    get: `${config.auditTrailUrl}/v1`,
    export: `${config.auditTrailUrl}/v1/csv/export`,
    search: `${config.auditTrailUrl}/v1/search`,
  },
  ruleChangeRequestService: {
    create: `${config.configurationUrl}/configs/change-requests`,
    createV2: `${config.configurationUrlV2}/configs/change-requests`,
    fetch: `${config.configurationUrl}/configs/change-requests?status=${API_PARAMS.status}`,
  },
  configPropagation: {
    copyInstance: `${config.configurationUrl}/rule-propagation/instance`,
  },
  userManagementService: {
    createUser: `${config.baseUrl}/user-management/users`,
    getUsers: `${config.baseUrl}/users/users-in-tenant/${API_PARAMS.tenantId}`,
    csvDownload: `${config.baseUrl}/user-management/users/csv`,
    getRoles: `${config.baseUrl}/user-management/roles`,
    getUsersWithRoles: `${config.baseUrl}/user-management/${API_PARAMS.tenantId}/users/roles`,
    updateRoles: `${config.baseUrl}/user-management/users/${API_PARAMS.userId}/roles`,
    bulkUpdateRoles: `${config.baseUrl}/user-management/users/roles`,
    userById: `${config.baseUrl}/user-management/users/${API_PARAMS.userId}`,
  },
  csvExporterService: {
    transactionList: `${config.csvCasesExporterUrl}/export/transaction`,
  },
};
