import moment from 'moment-timezone';

export function getLocale() {
  const locale = moment().tz(moment.tz.guess()).locale();
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23',
  };
  return { locale, options };
}

export function getDiffInSecondsTilNow(dateString) {
  if (!dateString || isNaN(new Date(dateString).getTime())) {
    return -1;
  }
  const now = new Date();
  const diffInMillis = now.getTime() - new Date(dateString).getTime();
  return parseInt(diffInMillis / 1000, 10);
}

export function getTimeToExpiredInSeconds(dateString, expiredInSeconds) {
  const diffInSeconds = getDiffInSecondsTilNow(dateString);
  let timeToExpiredInSeconds = expiredInSeconds - diffInSeconds;
  if (timeToExpiredInSeconds < 0) {
    timeToExpiredInSeconds = -1;
  }
  return timeToExpiredInSeconds;
}

export function isItemExpired(itemDateString, expiredInSeconds) {
  if (!itemDateString || isNaN(new Date(itemDateString).getTime())) {
    return true;
  }
  const timeToExpiredInSeconds = getTimeToExpiredInSeconds(itemDateString, expiredInSeconds);
  return timeToExpiredInSeconds < 0;
}

export function minusDate(baseDate, minusDays) {
  const result = new Date(baseDate);
  result.setDate(baseDate.getDate() - minusDays);
  return result;
}

export function startOfDay(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function endOfDay(date) {
  const result = startOfDay(minusDate(date, -1));
  result.setMilliseconds(result.getMilliseconds() - 1);
  return result;
}

export function endOfDayMomentJs(date) {
  return moment(date).set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
}

export function startOfMinuteAsString(date) {
  return moment(date)
    .set({
      second: 0,
      millisecond: 0,
    })
    .toISOString();
}

export function addMinutesToDate(date, minutes) {
  if (date) {
    const result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result.toISOString();
  }
  return undefined;
}

export function getLocaleDateOnly() {
  const locale = moment().tz(moment.tz.guess()).locale();
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return { locale, options };
}

export function shortLanguageDate(date) {
  let language;
  const today = moment().startOf('day');
  const dayDate = moment(date).startOf('day');
  const dayDiff = today.diff(dayDate, 'days', true);

  if (dayDiff === 0) {
    language = 'Today';
  } else if (dayDiff === 1) {
    language = 'Yesterday';
  }
  return language;
}

export function getLocaleDateString(date, shortLanguage) {
  if (date) {
    const locale = getLocaleDateOnly();
    locale.options.timeZone = 'UTC';
    return shortLanguage
      ? shortLanguageDate(date) ?? date.toLocaleDateString(locale.locale, locale.options)
      : date.toLocaleDateString(locale.locale, locale.options);
  }
  return '';
}

export function getLocaleDateTimeString(date) {
  if (date) {
    const locale = getLocale();
    return date.toLocaleDateString(locale.locale, locale.options);
  }
  return '';
}

export function getLocaleTimeString(date) {
  if (date) {
    const locale = getLocale();
    const options = { ...locale.options, year: undefined, month: undefined, day: undefined };
    return date.toLocaleTimeString(locale.locale, options);
  }
  return '';
}

export function appendTimestamp(date) {
  return `${date}T00:00:00.000Z`;
}

export const getModalOpeningTimestamp = (modalOpen, modalOpeningTimestamp) => {
  if (!modalOpen || !modalOpeningTimestamp) {
    return new Date().getTime();
  }

  return modalOpeningTimestamp;
};
