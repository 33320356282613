import { put, takeLatest } from 'redux-saga/effects';
import EntityResolutionAPI from 'modules/entity-resolution/middleware/entity-resolution-api';
import { startProcessing, stopProcessing } from 'middleware/reducers/processing-reducer';
import { getEntityResolutionProfileSuccess } from 'modules/entity-resolution/middleware/reducers/entity-resolution-profile-reducer';
import {
  getEntityResolutionConfigSuccess,
  saveEntityResolutionConfigSuccess,
} from 'modules/entity-resolution/middleware/reducers/entity-resolution-config-reducer';
import { showError } from 'middleware/actions/error';

const communicator = new EntityResolutionAPI();

function* getEntityResolutionConfigBegin(action) {
  yield put(startProcessing('entityResolutionConfig/getEntityResolutionConfig'));
  try {
    const json = yield communicator.getConfig();
    yield put(getEntityResolutionConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('entityResolutionConfig/getEntityResolutionConfig'));
}

export function* getEntityResolutionConfigWatcher() {
  yield takeLatest(
    'entityResolutionConfig/getEntityResolutionConfig',
    getEntityResolutionConfigBegin,
  );
}

function* saveEntityResolutionConfigBegin(action) {
  yield put(startProcessing('entityResolutionConfig/saveEntityResolutionConfig'));
  try {
    const json = yield communicator.updateConfig(action.payload);
    yield put(saveEntityResolutionConfigSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('entityResolutionConfig/saveEntityResolutionConfig'));
}

export function* saveEntityResolutionConfigWatcher() {
  yield takeLatest(
    'entityResolutionConfig/saveEntityResolutionConfig',
    saveEntityResolutionConfigBegin,
  );
}

function* getEntityResolutionProfileBegin(action) {
  yield put(startProcessing('entityResolutionProfile/getEntityResolutionProfile'));
  try {
    const json = yield communicator.getEntityProfile(action.payload?.customerIdToken);
    yield put(getEntityResolutionProfileSuccess(json));
  } catch (error) {
    yield put(showError({ ...error, action }));
  }
  yield put(stopProcessing('entityResolutionProfile/getEntityResolutionProfile'));
}

export function* getEntityResolutionProfileWatcher() {
  yield takeLatest(
    'entityResolutionProfile/getEntityResolutionProfile',
    getEntityResolutionProfileBegin,
  );
}
