import { ErrorActions } from 'middleware/action-types';
import ErrorCode from 'utils/constants/error-code';

const appendError = (errors, error) => {
  const existed = errors.find((e) => e.hide !== true && e.code === error.code);
  if (existed) {
    return [...errors];
  }
  error.createdDate = new Date();
  return [...errors, error];
};

const indexOf = (errors, error) => {
  let idx = -1;
  for (let i = 0; i < errors.length; i++) {
    if (errors[i].hide !== true && errors[i].code === error.code) {
      idx = i;
      break;
    }
  }
  return idx;
};

const replaceError = (errors, error) => {
  const idx = indexOf(errors, error);
  return [...errors.slice(0, idx), error, ...errors.slice(idx + 1)];
};

const containsErrorCode = (error) =>
  !!error &&
  error.code !== ErrorCode.NO_TENANT_ID &&
  error.code !== ErrorCode.CASE_DETAIL_DATA_NOT_FOUND &&
  error.code !== ErrorCode.MCC_LIST_DATA_NOT_FOUND &&
  error.code !== ErrorCode.RESET_CONTENT;

const getAlertType = (error) => (containsErrorCode(error) ? 'error' : 'warning');

const addCustomAttributes = (payload) => {
  const customPayload = {
    ...payload,
    custom: {
      alertType: getAlertType(payload),
    },
  };

  if (customPayload.code !== ErrorCode.RESET_CONTENT) return customPayload;

  delete customPayload.details;
  customPayload.custom.action = customPayload.action;

  if (customPayload.custom.action?.payload?.paging) {
    customPayload.custom.action.payload.paging.scrollId = null;
  } else if (customPayload.custom.action.paging) {
    customPayload.custom.action.paging.scrollId = null;
  }

  return customPayload;
};

const errorReducer = (state = {}, action) => {
  switch (action.type) {
    case ErrorActions.ERRORS_SHOW: {
      const errors = state.errors || [];
      const adjustedPayload = addCustomAttributes(action.payload);

      return {
        ...state,
        errors: appendError(errors, adjustedPayload),
      };
    }

    case ErrorActions.ERRORS_RETRY_BEGAN: {
      const errors = state.errors || [];
      const error = JSON.parse(JSON.stringify(action.payload));
      error.retriedDate = new Date();
      return {
        ...state,
        errors: replaceError(errors, error),
      };
    }

    case ErrorActions.ERRORS_HIDE: {
      const errors = state.errors || [];
      const error = JSON.parse(JSON.stringify(action.payload));
      error.hide = true;
      return {
        ...state,
        errors: replaceError(errors, error),
      };
    }

    default:
      return state;
  }
};

export default errorReducer;
