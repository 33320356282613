import { useState, useEffect, useRef } from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import Spinner from 'components/loaders/spinner';
import { useSelector } from 'react-redux';
import { getFirstPropertyInArray } from 'utils/helpers/utility';

/**
 * Loading button
 */
const LoadingButton = ({
  title,
  size,
  disableElevation = true,
  iconButtonMode,
  variant,
  color,
  style,
  startIcon,
  parentClick,
  isLoading,
  conditionalLoading,
  globalActionListener,
  onClick,
  multipleActions,
  className,
  id,
  type,
  fullWidth,
  spinnerModeOnly,
  isDisabled,
  'data-testid': dataTestId = '',
}) => {
  const processing = useSelector((state) => state.processing);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const buttonRef = useRef();
  const [minWidth, setMinWidth] = useState(0);

  /**
   * Enforces button with same action values doesn't get triggered
   * since its depending on redux state isLoading
   */

  const handleClick = (event) => {
    setIsClicked(true);
    if (onClick) {
      onClick(event);
    }
  };

  useEffect(() => {
    setMinWidth(buttonRef?.current?.clientWidth);
  }, []);

  useEffect(() => {
    if (parentClick) {
      parentClick.current = handleClick;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let tempActions = multipleActions;
    if (!Array.isArray(multipleActions) && multipleActions) {
      tempActions = [multipleActions];
    }

    let tempProcessing = getFirstPropertyInArray(tempActions, processing);
    tempProcessing = Boolean(isLoading || tempProcessing) && (isClicked || globalActionListener);
    if (!tempProcessing) {
      setIsClicked(false);
    }
    setIsProcessing(tempProcessing);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing, isDisabled, isLoading, conditionalLoading, isClicked]);

  if (spinnerModeOnly) {
    return (
      <Spinner
        size={15}
        thickness={4}
        color="inherit"
        className={`mb-n1xs ${className}`}
        isLoading={isProcessing}
      />
    );
  }

  if (iconButtonMode) {
    const disabled = isProcessing || isDisabled;
    const iconButton = (
      <IconButton
        data-testid={dataTestId}
        disabled={disabled}
        onClick={handleClick}
        ref={buttonRef}
        color={color}
      >
        {isProcessing ? (
          <Spinner size={24} thickness={4} color="inherit" isLoading={isProcessing} />
        ) : (
          startIcon
        )}
      </IconButton>
    );
    if (disabled) {
      return iconButton;
    }
    return <Tooltip title={title}>{iconButton}</Tooltip>;
  }

  return (
    <Button
      ref={buttonRef}
      id={id}
      data-testid={dataTestId}
      fullWidth={fullWidth}
      type={type}
      color={color}
      size={size}
      variant={variant}
      onClick={handleClick}
      disableElevation={disableElevation}
      style={{ ...style, minWidth }}
      disabled={isProcessing || isDisabled}
      className={className}
      startIcon={isProcessing ? null : startIcon}
    >
      <div>
        <Spinner
          size={16}
          thickness={4}
          color="inherit"
          className={`mb-n1xs ${startIcon ? 'mr-2 ' : ''}`}
          isLoading={isProcessing}
        />
        {isProcessing && !startIcon ? '' : title}
      </div>
    </Button>
  );
};

export { LoadingButton };
